<template>
  <v-toolbar flat dark color="#3E4095" class="mt-2">
    <v-spacer />
    <v-toolbar-title><slot /></v-toolbar-title>
    <v-spacer />
  </v-toolbar>
</template>

<script>
export default {
  components: {},

  data: () => ({}),
}
</script>
